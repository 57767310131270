<html xmlns="http://www.w3.org/1999/xhtml">

<head id="ctl00_Head1">


  <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
  <title>
    SECC Online Donations
  </title>
</head>

<body>


  <form name="aspnetForm" method="post"  id="aspnetForm">
    <div>
    </div>


    <div>
      <span id="dirtyForm" display="none"></span>
      <span id="ctl00_Label1"></span>
      <span id="ctl00_Timeout1" title="Session Expiring" style="display:none;">
        <p>
          <span class="ui-icon ui-icon-alert" style="float: left; margin: 1px 10px 20px 0;">
          </span>Your session will expire in.
        </p>
        <span id="tscCounter"></span>&nbsp;seconds
        <p>
          Click <b>OK</b> to continue your session.</p>
      </span>
    </div>
    <div id="wrapper">
      <div id="header">

        <img id="ctl00_imgHeader" src="assets/img/header.jpg" alt="SECC Header and Logo"
          style="height:190px;width:890px;border-width:0px;">

      </div>
      <div id="nav">

        <ul>
          <li>
            <a id="ctl00_LoginView1_LoginStatus1" title="Logout"
              href="admin/auth/dologout">LOGOUT</a>
          </li>

          <li><a id="ctl00_LoginView1_hlHome" title="Home" href="admin/home">HOME</a></li>
        </ul>

      </div>
      <div>

        <table width="100%" border="1">
          <tbody>
            <tr style="background-color:Aqua">
              <td>
                <div id="ctl00_LoginView2_UpdatePanel1">

                  <div style="width: 100%; height: 28px">
                    <div id="ctl00_LoginView2_timer" style="border-style:Solid;">

                      <div style="width: 100%; height: 100%; vertical-align: middle; text-align: center;">

                        <span id="ctl00_LoginView2_currentTime" style="font-size: large; font-weight: bold; ">
                          &nbsp;&nbsp;&nbsp;&nbsp;Payroll Deduction: {{adminSvc.donationSummaryList[0].prd | currency}} &nbsp;&nbsp;&nbsp;&nbsp;One Time
                          Payments/Special Events: {{adminSvc.donationSummaryList[0].cc | currency}}</span>
                      </div>

                    </div>
                  </div>

                </div>


              </td>
            </tr>
          </tbody>
        </table>

      </div>
      <div id="container">

        <div id="homewrap">
          <h2>
            Administrator options:</h2>
          <ul>
            <li><strong>Contributions entry from paper form</strong><br>
              Enter employee ID number, batch number, envelope number to start:<br>
              <br>
              <div style="float: left; padding-right: 10px;">
                <input name="ctl00$mainContent$getEmployee"  [(ngModel)]="adminSvc.employeeEin" type="text" id="ctl00_mainContent_getEmployee"
                  style="width:100px;">
                Employee ID



                <a ><img src="assets/img/find.png" id="ctl00_mainContent_es1_imgFind"
                    style="border: none;" alt="Click here to look up an employee id" (click)="showEmpIdLookupModal()"></a>

                <div id="ctl00_mainContent_es1_searchPanel"
                  style="display: none; position: absolute; width: 285px; top: 50%; left: 50%; margin-top: -40px; margin-left: -142px;">
                  <div style="border: solid #666 1px; background-color: #4A598C; color: #fff; width: 285px;">
                    <p style="margin: 0px; padding: 6px;">
                      <strong>Employee ID Lookup</strong>
                    </p>
                  </div>
                  <div style="border: solid #666 1px; background-color: #D3DCE8; width: 285px;">
                    <div style="float: left; padding: 8px;">
                      First Name<br>
                      <input name="ctl00$mainContent$es1$PayrolldbFirstName" type="text"
                        id="ctl00_mainContent_es1_PayrolldbFirstName" size="7">
                    </div>
                    <div style="float: left; padding: 8px;">
                      Last Name<br>
                      <input name="ctl00$mainContent$es1$PayrolldbLastName" type="text"
                        id="ctl00_mainContent_es1_PayrolldbLastName" size="7">&nbsp;<input type="button"
                        id="PayrolldbSearchButton" value="Search" onclick="executeNameSearch()">
                    </div>
                    <div style="clear: left; padding: 8px;">
                      <select name="ctl00$mainContent$es1$PayrolldbEmplIdList"
                        id="ctl00_mainContent_es1_PayrolldbEmplIdList" ondblclick="setEmplIdValue()">
                        <option value="enter a first and last name to search">enter a first and last name to search
                        </option>
                      </select>
                    </div>
                    <div style="padding: 8px;">
                      <input type="button" id="emplIdButton" value="Select"
                        onclick="setEmplIdValue()">&nbsp;&nbsp;&nbsp;
                      <input type="button" id="closeButton" value="Close" onclick="toggleSearchPanel())">
                    </div>
                  </div>
                </div><br>

                <input type="submit" name="ctl00$mainContent$StartButton" (click)="getEmployee()" value="Start"
                  id="ctl00_mainContent_StartButton">
                <div id="ctl00_mainContent_Message" class="reqd" *ngIf="isInvalidEmpId">Invalid Employee Id
                </div>
              </div>
              <div style="clear: left;">
                <br>
              </div>
            </li>
            <li>Contribution reports</li>
            <ul>
              <li><a href="admin/report/donationagency">Donations By Agency</a></li>
              <li><a href="admin/report/donationnonprofit">Donations By Nonprofit(s)</a></li>

            </ul>
            <br>
            <li>
              <a id="ctl00_mainContent_btnPayrollExtract"
                href="admin/api/payrollextract">Payroll Extract</a>
            </li>
            <li>
              <a id="ctl00_mainContent_btnVSUWExtract"
                href="admin/api/vsuwextract">VSUW Extract</a>
            </li>
            <li><a id="ctl00_mainContent_btnEOYExtract"
                href="admin/api/eoyextract">End of Year Charity Extract</a>
            </li>
            <li>
              <a id="ctl00_mainContent_btnManageSpecialEvents"
                href="admin/specialevents/list">Manage
                Special Events</a>
            </li>
            <li>
              <a id="ctl00_mainContent_hlDeleteDonation" href="admin/deletedonation">Delete Donation</a>
            </li>
          </ul>
        </div>
        <div id="summarywrap">
          <h2>
            Contributions Summary:
          </h2>
          <table width="100%">
            <tbody>
              <tr>
                <td>
                  <div>
                    <div>
                      <table cellspacing="0" rules="all" border="1" id="ctl00_mainContent_GridView1"
                        style="width:100%;border-collapse:collapse;">
                        <tbody>
                          <tr>
                            <th scope="col">Year</th>
                            <th scope="col">Payroll Deduction</th>
                            <th scope="col">One Time Payments/Special Events</th>
                          </tr>
                          <tr  *ngFor="let donationSummary of this.adminSvc.donationSummaryList">
                            <td>{{donationSummary.dYear}}</td>
                            <td align="right">
                              {{donationSummary.prd | currency}}
                            </td>
                            <td align="right">
                              {{donationSummary.cc | currency}}
                            </td>
                          </tr>

                        </tbody>
                      </table>
                    </div>

                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div id="footer">
        <ul>
          <li><b>Version: 2.0.0 </b>:: <a href="/">SECC Home</a> | <a href='admin/authorize'>Authorize</a></li>
        </ul>
      </div>
    </div>



  </form>


  <span id="okta-plugin-message-channel-available" style="display: none;"></span>
</body>

</html>
