import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DesignationsComponent } from '../app/donate/designations/designations.component';
import { ContributionsComponent } from '../app/donate/contributions/contributions.component';
import { PaymentmethodComponent } from './donate/paymentmethod/paymentmethod.component';
import { SubmissionComponent } from './donate/submission/submission.component';
import { ReceiptComponent } from './donate/receipt/receipt.component';
import { WelcomeComponent } from './donate/welcome/welcome.component';
import { HistoryComponent } from './donate/history/history.component';
import { LoginComponent } from './donate/login/login.component';
import { ThankyouComponent } from './donate/thankyou/thankyou.component';
import { NotyouComponent } from './donate/notyou/notyou.component';
import { PaymentcancelComponent } from './donate/paymentcancel/paymentcancel.component';
//Admin
import { AdminhomeComponent } from './donate/admin/adminhome/adminhome.component';
import { ContributionentryComponent } from './donate/admin/contributionentry/contributionentry.component';
import { AdminloginComponent } from './donate/admin/adminlogin/adminlogin.component';
import { AdminreceiptComponent } from './donate/admin/adminreceipt/adminreceipt.component';
import { DonationbyagencyComponent } from './donate/admin/report/donationbyagency/donationbyagency.component';
import { DonationbynonprofitComponent } from './donate/admin/report/donationbynonprofit/donationbynonprofit.component';
import { EmployeeidlookupComponent } from './donate/admin/employeeidlookup/employeeidlookup.component';
import { ListeventsComponent} from './donate/admin/managespecialevents/listevents/listevents.component';
import { AddeventComponent} from './donate/admin/managespecialevents/addevent/addevent.component';
import { EditeventComponent} from './donate/admin/managespecialevents/editevent/editevent.component';
import { DeletedonationComponent } from './donate/admin/deletedonation/deletedonation.component';
import { SpecialeventreportComponent } from './donate/admin/managespecialevents/report/specialeventreport/specialeventreport.component';
import { AuthorizeComponent } from './donate/admin/authorize/authorize.component';



const routes: Routes = [

  { path: '', redirectTo:'contributor/login',pathMatch:'full'},
  { path: 'contributor/login', component: LoginComponent },
  { path: 'contributor/welcome', component: WelcomeComponent },
  { path: 'contributor/history', component: HistoryComponent },
  { path: 'contributor/designations', component: DesignationsComponent },
  { path: 'contributor/contributions', component: ContributionsComponent },
  { path: 'contributor/paymentmethod', component: PaymentmethodComponent },
  { path: 'contributor/submission', component: SubmissionComponent },
  { path: 'contributor/receipt', component: ReceiptComponent },
  { path: 'contributor/thankyou', component: ThankyouComponent },
  { path: 'contributor/notyou', component: NotyouComponent },
  { path: 'contributor/paymentcancel', component: PaymentcancelComponent },

  //Admin
  { path: 'admin/login', component: AdminloginComponent },
  { path: 'admin/home', component: AdminhomeComponent },
  { path: 'admin/contributionentry', component: ContributionentryComponent },
  { path: 'admin/receipt', component: AdminreceiptComponent },
  { path: 'admin/report/donationagency', component: DonationbyagencyComponent },
  { path: 'admin/report/donationnonprofit', component: DonationbynonprofitComponent },
  { path: 'admin/employeeidlookup', component: EmployeeidlookupComponent },

  { path: 'admin/specialevents/list', component: ListeventsComponent },
  { path: 'admin/specialevents/add', component: AddeventComponent },
  { path: 'admin/specialevents/edit', component: EditeventComponent },
  { path: 'admin/specialevents/report', component: SpecialeventreportComponent },

  { path: 'admin/deletedonation', component: DeletedonationComponent },
  { path: 'admin/authorize', component: AuthorizeComponent }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
