<html xmlns="http://www.w3.org/1999/xhtml">

<head id="ctl00_Head1">
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <title>SECC Online Donations</title>
</head>

<body>
  <form name="aspnetForm" method="post" id="aspnetForm">
    <div></div>

    <div>
      <span id="dirtyForm" display="none"></span>
      <span id="ctl00_Label1"></span>
      <span id="ctl00_Timeout1" title="Session Expiring" style="display: none">
        <p>
          <span class="ui-icon ui-icon-alert" style="float: left; margin: 1px 10px 20px 0">
          </span>Your session will expire in.
        </p>
        <span id="tscCounter"></span>&nbsp;seconds
        <p>Click <b>OK</b> to continue your session.</p>
      </span>
    </div>
    <div id="wrapper">
      <div id="header">
        <img id="ctl00_imgHeader" src="assets/img/header.jpg" alt="SECC Header and Logo"
          style="height: 190px; width: 890px; border-width: 0px" />
      </div>

      <div id="nav">

        <ul>
          <li>
            <a id="ctl00_LoginView1_LoginStatus1" title="Logout" href="admin/auth/dologout">LOGOUT</a>
          </li>

          <li><a id="ctl00_LoginView1_hlHome" title="Home" href="admin/home">HOME</a></li>
        </ul>

      </div>

      <div>

        <table width="100%" border="1">
          <tbody>
            <tr style="background-color:Aqua">
              <td>
                <div id="ctl00_LoginView2_UpdatePanel1">

                  <div style="width: 100%; height: 28px">
                    <div id="ctl00_LoginView2_timer" style="border-style:Solid;">


                      <div style="width: 100%; height: 100%; vertical-align: middle; text-align: center;">
                        <span id="ctl00_LoginView2_currentTime" style="font-size: large; font-weight: bold; ">
                          &nbsp;&nbsp;&nbsp;&nbsp;Payroll Deduction: {{bannerSvc.donationSummaryList[0].prd | currency}}
                          &nbsp;&nbsp;&nbsp;&nbsp;One Time
                          Payments/Special Events: {{bannerSvc.donationSummaryList[0].cc | currency}}</span>
                      </div>


                    </div>
                  </div>

                </div>


              </td>
            </tr>
          </tbody>
        </table>

      </div>

      <div id="container">


        SECC CHARITY & SECC DONATE Admins : <p></p>
        <p></p>
        <table>

          <tr *ngFor="let item of authorizedAdmins">
            <td>{{item.ein}}</td>
            <td>|</td>
            <td>{{item.firstName}}</td>
            <td>{{item.lastName}}</td>
            <td><img src="assets/img/delete.png" style="cursor: pointer;" (click)="deleteAdmin(item.ein)"></td>
          </tr>
          <tr><td>&nbsp;</td></tr>
          <tr><td>&nbsp;</td></tr>
          <tr><td>&nbsp;</td></tr>

        </table>

        <div>
          <table>
            <tr>
              <td>
            <form id="AddNewForm" method="post">
              <input type="text" size="9" id="newEinBox" name="newEinBox" (keyup.enter)="saveAdmin()" [(ngModel)]="newEin" placeholder="< EIN >"/>
            </form>
          </td>
          <td>
            <input type="button" (click)="saveAdmin()" value="Add" />
          </td>
          </tr>
          </table>
        </div>



      </div>

      <div id="footer">
        <ul>
          <li><b>Version: 2.0.0 </b>:: <a href="/">SECC Home</a></li>
        </ul>
      </div>
    </div>
  </form>

  <span id="okta-plugin-message-channel-available" style="display: none"></span>
</body>

</html>
